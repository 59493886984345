import * as i0 from '@angular/core';
import { forwardRef, Directive, Input, NgModule } from '@angular/core';
export { NgModule } from '@angular/core';
import { Validators, NG_VALIDATORS, FormControl, NgModel } from '@angular/forms';
import { isEmail, isURL } from 'validator';
function isPresent(obj) {
  return obj !== undefined && obj !== null;
}
function isDate(obj) {
  try {
    const date = new Date(obj);
    return !isNaN(date.getTime());
  } catch (e) {
    return false;
  }
}
function parseDate(obj) {
  try {
    // Moment.js
    if (obj._d instanceof Date) {
      const d = obj._d;
      const month = +d.getMonth() + 1;
      const day = +d.getDate();
      return `${d.getFullYear()}-${formatDayOrMonth(month)}-${formatDayOrMonth(day)}`;
    }
    // NgbDateStruct
    if (typeof obj === 'object' && obj.year != null && obj.month != null && obj.day != null) {
      const month = +obj.month;
      const day = +obj.day;
      return `${obj.year}-${formatDayOrMonth(month)}-${formatDayOrMonth(day)}`;
    }
  } catch (e) {}
  return obj;
}
function formatDayOrMonth(month) {
  return month < 10 ? `0${month}` : month;
}
const arrayLength = value => {
  return control => {
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const obj = control.value;
    return Array.isArray(obj) && obj.length >= +value ? null : {
      arrayLength: {
        minLength: value
      }
    };
  };
};
const ARRAY_LENGTH_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ArrayLengthValidator),
  multi: true
};
class ArrayLengthValidator {
  arrayLength;
  validator;
  onChange;
  ngOnInit() {
    this.validator = arrayLength(this.arrayLength);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'arrayLength') {
        this.validator = arrayLength(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function ArrayLengthValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ArrayLengthValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: ArrayLengthValidator,
    selectors: [["", "ngv-arrayLength", "", "formControlName", ""], ["", "ngv-arrayLength", "", "formControl", ""], ["", "ngv-arrayLength", "", "ngModel", ""]],
    inputs: {
      arrayLength: "arrayLength"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([ARRAY_LENGTH_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ArrayLengthValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-arrayLength][formControlName],[ngv-arrayLength][formControl],[ngv-arrayLength][ngModel]',
      providers: [ARRAY_LENGTH_VALIDATOR],
      standalone: true
    }]
  }], null, {
    arrayLength: [{
      type: Input
    }]
  });
})();
const base64 = control => {
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v = control.value;
  return /^(?:[A-Z0-9+\/]{4})*(?:[A-Z0-9+\/]{2}==|[A-Z0-9+\/]{3}=|[A-Z0-9+\/]{4})$/i.test(v) ? null : {
    base64: true
  };
};
const BASE64_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => Base64Validator),
  multi: true
};
class Base64Validator {
  validate(c) {
    return base64(c);
  }
  static ɵfac = function Base64Validator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || Base64Validator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: Base64Validator,
    selectors: [["", "ngv-base64", "", "formControlName", ""], ["", "ngv-base64", "", "formControl", ""], ["", "ngv-base64", "", "ngModel", ""]],
    standalone: true,
    features: [i0.ɵɵProvidersFeature([BASE64_VALIDATOR])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(Base64Validator, [{
    type: Directive,
    args: [{
      selector: '[ngv-base64][formControlName],[ngv-base64][formControl],[ngv-base64][ngModel]',
      providers: [BASE64_VALIDATOR],
      standalone: true
    }]
  }], null, null);
})();
const creditCard = control => {
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v = control.value;
  const sanitized = v.replace(/[^0-9]+/g, '');
  // problem with chrome
  /* tslint:disable */
  if (!/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11}|(?:9792)\d{12})$/.test(sanitized)) {
    return {
      creditCard: true
    };
  }
  /* tslint:enable */
  let sum = 0;
  let digit;
  let tmpNum;
  let shouldDouble;
  for (let i = sanitized.length - 1; i >= 0; i--) {
    digit = sanitized.substring(i, i + 1);
    tmpNum = parseInt(digit, 10);
    if (shouldDouble) {
      tmpNum *= 2;
      if (tmpNum >= 10) {
        sum += tmpNum % 10 + 1;
      } else {
        sum += tmpNum;
      }
    } else {
      sum += tmpNum;
    }
    shouldDouble = !shouldDouble;
  }
  if (Boolean(sum % 10 === 0 ? sanitized : false)) {
    return null;
  }
  return {
    creditCard: true
  };
};
const CREDIT_CARD_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => CreditCardValidator),
  multi: true
};
class CreditCardValidator {
  validate(c) {
    return creditCard(c);
  }
  static ɵfac = function CreditCardValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CreditCardValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: CreditCardValidator,
    selectors: [["", "ngv-creditCard", "", "formControlName", ""], ["", "ngv-creditCard", "", "formControl", ""], ["", "ngv-creditCard", "", "ngModel", ""]],
    standalone: true,
    features: [i0.ɵɵProvidersFeature([CREDIT_CARD_VALIDATOR])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CreditCardValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-creditCard][formControlName],[ngv-creditCard][formControl],[ngv-creditCard][ngModel]',
      providers: [CREDIT_CARD_VALIDATOR],
      standalone: true
    }]
  }], null, null);
})();
const dateISO = control => {
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v = control.value;
  return /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/.test(v) ? null : {
    dateISO: true
  };
};
const DATE_ISO_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => DateISOValidator),
  multi: true
};
class DateISOValidator {
  validate(c) {
    return dateISO(c);
  }
  static ɵfac = function DateISOValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DateISOValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: DateISOValidator,
    selectors: [["", "ngv-dateISO", "", "formControlName", ""], ["", "ngv-dateISO", "", "formControl", ""], ["", "ngv-dateISO", "", "ngModel", ""]],
    standalone: true,
    features: [i0.ɵɵProvidersFeature([DATE_ISO_VALIDATOR])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateISOValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-dateISO][formControlName],[ngv-dateISO][formControl],[ngv-dateISO][ngModel]',
      providers: [DATE_ISO_VALIDATOR],
      standalone: true
    }]
  }], null, null);
})();
const date = control => {
  if (isPresent(Validators.required(control))) {
    return null;
  }
  let v = control.value;
  v = parseDate(v);
  return isDate(v) ? null : {
    date: true
  };
};
const DATE_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => DateValidator),
  multi: true
};
class DateValidator {
  validate(c) {
    return date(c);
  }
  static ɵfac = function DateValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DateValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: DateValidator,
    selectors: [["", "ngv-date", "", "formControlName", ""], ["", "ngv-date", "", "formControl", ""], ["", "ngv-date", "", "ngModel", ""]],
    standalone: true,
    features: [i0.ɵɵProvidersFeature([DATE_VALIDATOR])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DateValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-date][formControlName],[ngv-date][formControl],[ngv-date][ngModel]',
      providers: [DATE_VALIDATOR],
      standalone: true
    }]
  }], null, null);
})();
const digits = control => {
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v = control.value;
  return /^\d+$/.test(v) ? null : {
    digits: true
  };
};
const DIGITS_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => DigitsValidator),
  multi: true
};
class DigitsValidator {
  validate(c) {
    return digits(c);
  }
  static ɵfac = function DigitsValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || DigitsValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: DigitsValidator,
    selectors: [["", "ngv-digits", "", "formControlName", ""], ["", "ngv-digits", "", "formControl", ""], ["", "ngv-digits", "", "ngModel", ""]],
    standalone: true,
    features: [i0.ɵɵProvidersFeature([DIGITS_VALIDATOR])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DigitsValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-digits][formControlName],[ngv-digits][formControl],[ngv-digits][ngModel]',
      providers: [DIGITS_VALIDATOR],
      standalone: true
    }]
  }], null, null);
})();
const email = (control, options) => {
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v = control.value;
  return isEmail(v, options) ? null : {
    email: true
  };
};
const EMAIL_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => EmailValidator),
  multi: true
};
class EmailValidator {
  /** validation options defaulting to
   * { allow_display_name: false, require_display_name: false, allow_utf8_local_part: true, require_tld: true, allow_ip_domain: false, allow_underscores: false, domain_specific_validation: false, blacklisted_chars: '', host_blacklist: [] }
   */
  emailOptions;
  validate(c) {
    return email(c, this.emailOptions ? this.emailOptions : undefined);
  }
  static ɵfac = function EmailValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || EmailValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: EmailValidator,
    selectors: [["", "ngv-email", "", "formControlName", ""], ["", "ngv-email", "", "formControl", ""], ["", "ngv-email", "", "ngModel", ""]],
    inputs: {
      emailOptions: [0, "ngv-email", "emailOptions"]
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([EMAIL_VALIDATOR])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EmailValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-email][formControlName],[ngv-email][formControl],[ngv-email][ngModel]',
      providers: [EMAIL_VALIDATOR],
      standalone: true
    }]
  }], null, {
    emailOptions: [{
      type: Input,
      args: ["ngv-email"]
    }]
  });
})();
const equalTo = equalControl => {
  let subscribe = false;
  return control => {
    if (!subscribe) {
      subscribe = true;
      equalControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }
    const v = control.value;
    return equalControl.value === v ? null : {
      equalTo: {
        control: equalControl,
        value: equalControl.value
      }
    };
  };
};
const EQUAL_TO_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => EqualToValidator),
  multi: true
};
class EqualToValidator {
  equalTo;
  validator;
  ngOnInit() {
    this.validator = equalTo(this.equalTo);
  }
  validate(c) {
    return this.validator(c);
  }
  static ɵfac = function EqualToValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || EqualToValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: EqualToValidator,
    selectors: [["", "ngv-equalTo", "", "formControlName", ""], ["", "ngv-equalTo", "", "formControl", ""], ["", "ngv-equalTo", "", "ngModel", ""]],
    inputs: {
      equalTo: "equalTo"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([EQUAL_TO_VALIDATOR])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EqualToValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-equalTo][formControlName],[ngv-equalTo][formControl],[ngv-equalTo][ngModel]',
      providers: [EQUAL_TO_VALIDATOR],
      standalone: true
    }]
  }], null, {
    equalTo: [{
      type: Input
    }]
  });
})();
const equal = val => {
  return control => {
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v = control.value;
    return val === v ? null : {
      equal: {
        value: val
      }
    };
  };
};
const EQUAL_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => EqualValidator),
  multi: true
};
class EqualValidator {
  equal;
  validator;
  onChange;
  ngOnInit() {
    this.validator = equal(this.equal);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'equal') {
        this.validator = equal(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function EqualValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || EqualValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: EqualValidator,
    selectors: [["", "ngv-equal", "", "formControlName", ""], ["", "ngv-equal", "", "formControl", ""], ["", "ngv-equal", "", "ngModel", ""]],
    inputs: {
      equal: "equal"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([EQUAL_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(EqualValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-equal][formControlName],[ngv-equal][formControl],[ngv-equal][ngModel]',
      providers: [EQUAL_VALIDATOR],
      standalone: true
    }]
  }], null, {
    equal: [{
      type: Input
    }]
  });
})();
const gte = value => {
  return control => {
    if (!isPresent(value)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v = +control.value;
    return v >= +value ? null : {
      gte: {
        value: value
      }
    };
  };
};
const GREATER_THAN_EQUAL_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => GreaterThanEqualValidator),
  multi: true
};
class GreaterThanEqualValidator {
  gte;
  validator;
  onChange;
  ngOnInit() {
    this.validator = gte(this.gte);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'gte') {
        this.validator = gte(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function GreaterThanEqualValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || GreaterThanEqualValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: GreaterThanEqualValidator,
    selectors: [["", "ngv-gte", "", "formControlName", ""], ["", "ngv-gte", "", "formControl", ""], ["", "ngv-gte", "", "ngModel", ""]],
    inputs: {
      gte: "gte"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([GREATER_THAN_EQUAL_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GreaterThanEqualValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-gte][formControlName],[ngv-gte][formControl],[ngv-gte][ngModel]',
      providers: [GREATER_THAN_EQUAL_VALIDATOR],
      standalone: true
    }]
  }], null, {
    gte: [{
      type: Input
    }]
  });
})();
const gt = value => {
  return control => {
    if (!isPresent(value)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v = +control.value;
    return v > +value ? null : {
      gt: {
        value: value
      }
    };
  };
};
const GREATER_THAN_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => GreaterThanValidator),
  multi: true
};
class GreaterThanValidator {
  gt;
  validator;
  onChange;
  ngOnInit() {
    this.validator = gt(this.gt);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'gt') {
        this.validator = gt(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function GreaterThanValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || GreaterThanValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: GreaterThanValidator,
    selectors: [["", "ngv-gt", "", "formControlName", ""], ["", "ngv-gt", "", "formControl", ""], ["", "ngv-gt", "", "ngModel", ""]],
    inputs: {
      gt: "gt"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([GREATER_THAN_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GreaterThanValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-gt][formControlName],[ngv-gt][formControl],[ngv-gt][ngModel]',
      providers: [GREATER_THAN_VALIDATOR],
      standalone: true
    }]
  }], null, {
    gt: [{
      type: Input
    }]
  });
})();
const includedIn = includedInArr => {
  return control => {
    if (!isPresent(includedInArr)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    if (includedInArr.indexOf(control.value) < 0) {
      return {
        includedIn: {
          value: control.value,
          reason: includedInArr
        }
      };
    }
    return null;
  };
};
const INCLUDED_IN_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => IncludedInValidator),
  multi: true
};
class IncludedInValidator {
  includedIn;
  validator;
  onChange;
  ngOnInit() {
    this.validator = includedIn(this.includedIn);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'includedIn') {
        this.validator = includedIn(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function IncludedInValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || IncludedInValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: IncludedInValidator,
    selectors: [["", "ngv-includedIn", "", "formControlName", ""], ["", "ngv-includedIn", "", "formControl", ""], ["", "ngv-includedIn", "", "ngModel", ""]],
    inputs: {
      includedIn: "includedIn"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([INCLUDED_IN_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IncludedInValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-includedIn][formControlName],[ngv-includedIn][formControl],[ngv-includedIn][ngModel]',
      providers: [INCLUDED_IN_VALIDATOR],
      standalone: true
    }]
  }], null, {
    includedIn: [{
      type: Input
    }]
  });
})();
const json = control => {
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v = control.value;
  try {
    const obj = JSON.parse(v);
    if (Boolean(obj) && typeof obj === 'object') {
      return null;
    }
  } catch (e) {}
  return {
    json: true
  };
};
const JSON_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => JSONValidator),
  multi: true
};
class JSONValidator {
  validate(c) {
    return json(c);
  }
  static ɵfac = function JSONValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || JSONValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: JSONValidator,
    selectors: [["", "ngv-json", "", "formControlName", ""], ["", "ngv-json", "", "formControl", ""], ["", "ngv-json", "", "ngModel", ""]],
    standalone: true,
    features: [i0.ɵɵProvidersFeature([JSON_VALIDATOR])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(JSONValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-json][formControlName],[ngv-json][formControl],[ngv-json][ngModel]',
      providers: [JSON_VALIDATOR],
      standalone: true
    }]
  }], null, null);
})();
const lte = value => {
  return control => {
    if (!isPresent(value)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v = +control.value;
    return v <= +value ? null : {
      lte: {
        value: value
      }
    };
  };
};
const LESS_THAN_EQUAL_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => LessThanEqualValidator),
  multi: true
};
class LessThanEqualValidator {
  lte;
  validator;
  onChange;
  ngOnInit() {
    this.validator = lte(this.lte);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'lte') {
        this.validator = lte(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function LessThanEqualValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LessThanEqualValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: LessThanEqualValidator,
    selectors: [["", "ngv-lte", "", "formControlName", ""], ["", "ngv-lte", "", "formControl", ""], ["", "ngv-lte", "", "ngModel", ""]],
    inputs: {
      lte: "lte"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([LESS_THAN_EQUAL_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LessThanEqualValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-lte][formControlName],[ngv-lte][formControl],[ngv-lte][ngModel]',
      providers: [LESS_THAN_EQUAL_VALIDATOR],
      standalone: true
    }]
  }], null, {
    lte: [{
      type: Input
    }]
  });
})();
const lt = value => {
  return control => {
    if (!isPresent(value)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v = +control.value;
    return v < +value ? null : {
      lt: {
        value: value
      }
    };
  };
};
const LESS_THAN_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => LessThanValidator),
  multi: true
};
class LessThanValidator {
  lt;
  validator;
  onChange;
  ngOnInit() {
    this.validator = lt(this.lt);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'lt') {
        this.validator = lt(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function LessThanValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || LessThanValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: LessThanValidator,
    selectors: [["", "ngv-lt", "", "formControlName", ""], ["", "ngv-lt", "", "formControl", ""], ["", "ngv-lt", "", "ngModel", ""]],
    inputs: {
      lt: "lt"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([LESS_THAN_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LessThanValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-lt][formControlName],[ngv-lt][formControl],[ngv-lt][ngModel]',
      providers: [LESS_THAN_VALIDATOR],
      standalone: true
    }]
  }], null, {
    lt: [{
      type: Input
    }]
  });
})();
const maxDate = maxInput => {
  let value;
  let subscribe = false;
  let maxValue = maxInput;
  const isForm = maxInput instanceof FormControl || maxInput instanceof NgModel;
  return control => {
    if (!subscribe && isForm) {
      subscribe = true;
      maxInput.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }
    if (isForm) {
      maxValue = maxInput.value;
    }
    value = parseDate(maxValue);
    if (!isDate(value) && !(value instanceof Function)) {
      if (value == null) {
        return null;
      } else if (isForm) {
        return {
          maxDate: {
            error: 'maxDate is invalid'
          }
        };
      } else {
        throw Error('maxDate value must be or return a formatted date');
      }
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const d = new Date(parseDate(control.value)).getTime();
    if (!isDate(d)) {
      return {
        value: true
      };
    }
    if (value instanceof Function) {
      value = value();
    }
    return d <= new Date(value).getTime() ? null : isForm ? {
      maxDate: {
        control: maxInput,
        value: maxInput.value
      }
    } : {
      maxDate: {
        value: maxValue,
        control: undefined
      }
    };
  };
};
const MAX_DATE_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MaxDateValidator),
  multi: true
};
class MaxDateValidator {
  maxDate;
  validator;
  onChange;
  ngOnInit() {
    this.validator = maxDate(this.maxDate);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'maxDate') {
        this.validator = maxDate(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function MaxDateValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MaxDateValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: MaxDateValidator,
    selectors: [["", "ngv-maxDate", "", "formControlName", ""], ["", "ngv-maxDate", "", "formControl", ""], ["", "ngv-maxDate", "", "ngModel", ""]],
    inputs: {
      maxDate: "maxDate"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([MAX_DATE_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaxDateValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-maxDate][formControlName],[ngv-maxDate][formControl],[ngv-maxDate][ngModel]',
      providers: [MAX_DATE_VALIDATOR],
      standalone: true
    }]
  }], null, {
    maxDate: [{
      type: Input
    }]
  });
})();
const max = value => {
  return control => {
    if (!isPresent(value)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v = +control.value;
    return v <= +value ? null : {
      max: {
        value: value
      }
    };
  };
};
const MAX_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MaxValidator),
  multi: true
};
class MaxValidator {
  max;
  validator;
  onChange;
  ngOnInit() {
    this.validator = max(this.max);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'max') {
        this.validator = max(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function MaxValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MaxValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: MaxValidator,
    selectors: [["", "ngv-max", "", "formControlName", ""], ["", "ngv-max", "", "formControl", ""], ["", "ngv-max", "", "ngModel", ""]],
    inputs: {
      max: "max"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([MAX_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaxValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-max][formControlName],[ngv-max][formControl],[ngv-max][ngModel]',
      providers: [MAX_VALIDATOR],
      standalone: true
    }]
  }], null, {
    max: [{
      type: Input
    }]
  });
})();
const minDate = minInput => {
  let value;
  let subscribe = false;
  let minValue = minInput;
  const isForm = minInput instanceof FormControl || minInput instanceof NgModel;
  return control => {
    if (!subscribe && isForm) {
      subscribe = true;
      minInput.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }
    if (isForm) {
      minValue = minInput.value;
    }
    value = parseDate(minValue);
    if (!isDate(value) && !(value instanceof Function)) {
      if (value == null) {
        return null;
      } else if (isForm) {
        return {
          minDate: {
            error: 'minDate is invalid'
          }
        };
      } else {
        throw Error('minDate value must be or return a formatted date');
      }
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const d = new Date(parseDate(control.value)).getTime();
    if (!isDate(d)) {
      return {
        value: true
      };
    }
    if (value instanceof Function) {
      value = value();
    }
    return d >= new Date(value).getTime() ? null : isForm ? {
      minDate: {
        control: minInput,
        value: minInput.value
      }
    } : {
      minDate: {
        value: minValue,
        control: undefined
      }
    };
  };
};
const MIN_DATE_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MinDateValidator),
  multi: true
};
class MinDateValidator {
  minDate;
  validator;
  onChange;
  ngOnInit() {
    this.validator = minDate(this.minDate);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'minDate') {
        this.validator = minDate(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function MinDateValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MinDateValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: MinDateValidator,
    selectors: [["", "ngv-minDate", "", "formControlName", ""], ["", "ngv-minDate", "", "formControl", ""], ["", "ngv-minDate", "", "ngModel", ""]],
    inputs: {
      minDate: "minDate"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([MIN_DATE_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MinDateValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-minDate][formControlName],[ngv-minDate][formControl],[ngv-minDate][ngModel]',
      providers: [MIN_DATE_VALIDATOR],
      standalone: true
    }]
  }], null, {
    minDate: [{
      type: Input
    }]
  });
})();
const min = value => {
  return control => {
    if (!isPresent(value)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v = +control.value;
    return v >= +value ? null : {
      min: {
        value: value
      }
    };
  };
};
const MIN_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => MinValidator),
  multi: true
};
class MinValidator {
  min;
  validator;
  onChange;
  ngOnInit() {
    this.validator = min(this.min);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'min') {
        this.validator = min(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function MinValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || MinValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: MinValidator,
    selectors: [["", "ngv-min", "", "formControlName", ""], ["", "ngv-min", "", "formControl", ""], ["", "ngv-min", "", "ngModel", ""]],
    inputs: {
      min: "min"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([MIN_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MinValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-min][formControlName],[ngv-min][formControl],[ngv-min][ngModel]',
      providers: [MIN_VALIDATOR],
      standalone: true
    }]
  }], null, {
    min: [{
      type: Input
    }]
  });
})();
const notEqualTo = notEqualControl => {
  let subscribe = false;
  return control => {
    if (!subscribe) {
      subscribe = true;
      notEqualControl.valueChanges.subscribe(() => {
        control.updateValueAndValidity();
      });
    }
    const v = control.value;
    if (notEqualControl.value == null && v == null) {
      return null;
    }
    return notEqualControl.value !== v ? null : {
      notEqualTo: {
        control: notEqualControl,
        value: notEqualControl.value
      }
    };
  };
};
const NOT_EQUAL_TO_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => NotEqualToValidator),
  multi: true
};
class NotEqualToValidator {
  notEqualTo;
  validator;
  ngOnInit() {
    this.validator = notEqualTo(this.notEqualTo);
  }
  validate(c) {
    return this.validator(c);
  }
  static ɵfac = function NotEqualToValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NotEqualToValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: NotEqualToValidator,
    selectors: [["", "ngv-notEqualTo", "", "formControlName", ""], ["", "ngv-notEqualTo", "", "formControl", ""], ["", "ngv-notEqualTo", "", "ngModel", ""]],
    inputs: {
      notEqualTo: "notEqualTo"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([NOT_EQUAL_TO_VALIDATOR])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotEqualToValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-notEqualTo][formControlName],[ngv-notEqualTo][formControl],[ngv-notEqualTo][ngModel]',
      providers: [NOT_EQUAL_TO_VALIDATOR],
      standalone: true
    }]
  }], null, {
    notEqualTo: [{
      type: Input
    }]
  });
})();
const notEqual = val => {
  return control => {
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v = control.value;
    return val !== v ? null : {
      notEqual: {
        value: val
      }
    };
  };
};
const NOT_EQUAL_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => NotEqualValidator),
  multi: true
};
class NotEqualValidator {
  notEqual;
  validator;
  onChange;
  ngOnInit() {
    this.validator = notEqual(this.notEqual);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'notEqual') {
        this.validator = notEqual(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function NotEqualValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NotEqualValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: NotEqualValidator,
    selectors: [["", "ngv-notEqual", "", "formControlName", ""], ["", "ngv-notEqual", "", "formControl", ""], ["", "ngv-notEqual", "", "ngModel", ""]],
    inputs: {
      notEqual: "notEqual"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([NOT_EQUAL_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotEqualValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-notEqual][formControlName],[ngv-notEqual][formControl],[ngv-notEqual][ngModel]',
      providers: [NOT_EQUAL_VALIDATOR],
      standalone: true
    }]
  }], null, {
    notEqual: [{
      type: Input
    }]
  });
})();
const notIncludedIn = includedInArr => {
  return control => {
    if (!isPresent(includedInArr)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    if (includedInArr.indexOf(control.value) >= 0) {
      return {
        notIncludedIn: {
          value: control.value,
          reason: includedInArr
        }
      };
    }
    return null;
  };
};
const NOT_INCLUDED_IN_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => NotIncludedInValidator),
  multi: true
};
class NotIncludedInValidator {
  notIncludedIn;
  validator;
  onChange;
  ngOnInit() {
    this.validator = notIncludedIn(this.notIncludedIn);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'notIncludedIn') {
        this.validator = notIncludedIn(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function NotIncludedInValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NotIncludedInValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: NotIncludedInValidator,
    selectors: [["", "ngv-notIncludedIn", "", "formControlName", ""], ["", "ngv-notIncludedIn", "", "formControl", ""], ["", "ngv-notIncludedIn", "", "ngModel", ""]],
    inputs: {
      notIncludedIn: "notIncludedIn"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([NOT_INCLUDED_IN_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotIncludedInValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-notIncludedIn][formControlName],[ngv-notIncludedIn][formControl],[ngv-notIncludedIn][ngModel]',
      providers: [NOT_INCLUDED_IN_VALIDATOR],
      standalone: true
    }]
  }], null, {
    notIncludedIn: [{
      type: Input
    }]
  });
})();
const notMatching = p => {
  if (!isPresent(p)) {
    return control => null;
  }
  const patternValidator = Validators.pattern(p);
  return control => {
    if (isPresent(Validators.required(control))) {
      return null;
    }
    if (!patternValidator(control)) {
      return {
        notMatching: {
          value: control.value,
          reason: p
        }
      };
    }
    return null;
  };
};
const NOT_MATCHING_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => NotMatchingValidator),
  multi: true
};
class NotMatchingValidator {
  notMatching;
  validator;
  onChange;
  ngOnInit() {
    this.validator = notMatching(this.notMatching);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'notMatching') {
        this.validator = notMatching(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function NotMatchingValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NotMatchingValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: NotMatchingValidator,
    selectors: [["", "ngv-notMatching", "", "formControlName", ""], ["", "ngv-notMatching", "", "formControl", ""], ["", "ngv-notMatching", "", "ngModel", ""]],
    inputs: {
      notMatching: "notMatching"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([NOT_MATCHING_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NotMatchingValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-notMatching][formControlName],[ngv-notMatching][formControl],[ngv-notMatching][ngModel]',
      providers: [NOT_MATCHING_VALIDATOR],
      standalone: true
    }]
  }], null, {
    notMatching: [{
      type: Input
    }]
  });
})();

// tslint:disable-next-line:variable-name
const number = control => {
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v = control.value;
  return /^(?:-?\d+|-?\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/.test(v) ? null : {
    number: true
  };
};
const NUMBER_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => NumberValidator),
  multi: true
};
class NumberValidator {
  validate(c) {
    return number(c);
  }
  static ɵfac = function NumberValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NumberValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: NumberValidator,
    selectors: [["", "ngv-number", "", "formControlName", ""], ["", "ngv-number", "", "formControl", ""], ["", "ngv-number", "", "ngModel", ""]],
    standalone: true,
    features: [i0.ɵɵProvidersFeature([NUMBER_VALIDATOR])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumberValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-number][formControlName],[ngv-number][formControl],[ngv-number][ngModel]',
      providers: [NUMBER_VALIDATOR],
      standalone: true
    }]
  }], null, null);
})();
const property = value => {
  return control => {
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const properties = value.split(',');
    const obj = control.value;
    let isValid = true;
    for (const prop of properties) {
      if (obj[prop] == null) {
        isValid = false;
        break;
      }
    }
    return isValid ? null : {
      hasProperty: {
        value: value
      }
    };
  };
};
const PROPERTY_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => PropertyValidator),
  multi: true
};
class PropertyValidator {
  property;
  validator;
  onChange;
  ngOnInit() {
    this.validator = property(this.property);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'property') {
        this.validator = property(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function PropertyValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || PropertyValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: PropertyValidator,
    selectors: [["", "ngv-property", "", "formControlName", ""], ["", "ngv-property", "", "formControl", ""], ["", "ngv-property", "", "ngModel", ""]],
    inputs: {
      property: "property"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([PROPERTY_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PropertyValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-property][formControlName],[ngv-property][formControl],[ngv-property][ngModel]',
      providers: [PROPERTY_VALIDATOR],
      standalone: true
    }]
  }], null, {
    property: [{
      type: Input
    }]
  });
})();
const rangeLength = value => {
  return control => {
    if (!isPresent(value)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v = control.value;
    return v.length >= value[0] && v.length <= value[1] ? null : {
      rangeLength: {
        value: value
      }
    };
  };
};
const RANGE_LENGTH_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => RangeLengthValidator),
  multi: true
};
class RangeLengthValidator {
  rangeLength;
  validator;
  onChange;
  ngOnInit() {
    this.validator = rangeLength(this.rangeLength);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'rangeLength') {
        this.validator = rangeLength(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function RangeLengthValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || RangeLengthValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: RangeLengthValidator,
    selectors: [["", "ngv-rangeLength", "", "formControlName", ""], ["", "ngv-rangeLength", "", "formControl", ""], ["", "ngv-rangeLength", "", "ngModel", ""]],
    inputs: {
      rangeLength: "rangeLength"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([RANGE_LENGTH_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RangeLengthValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-rangeLength][formControlName],[ngv-rangeLength][formControl],[ngv-rangeLength][ngModel]',
      providers: [RANGE_LENGTH_VALIDATOR],
      standalone: true
    }]
  }], null, {
    rangeLength: [{
      type: Input
    }]
  });
})();
const range = value => {
  return control => {
    if (!isPresent(value)) {
      return null;
    }
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v = +control.value;
    return v >= value[0] && v <= value[1] ? null : {
      range: {
        value: value
      }
    };
  };
};
const RANGE_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => RangeValidator),
  multi: true
};
class RangeValidator {
  range;
  validator;
  onChange;
  ngOnInit() {
    this.validator = range(this.range);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'range') {
        this.validator = range(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function RangeValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || RangeValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: RangeValidator,
    selectors: [["", "ngv-range", "", "formControlName", ""], ["", "ngv-range", "", "formControl", ""], ["", "ngv-range", "", "ngModel", ""]],
    inputs: {
      range: "range"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([RANGE_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RangeValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-range][formControlName],[ngv-range][formControl],[ngv-range][ngModel]',
      providers: [RANGE_VALIDATOR],
      standalone: true
    }]
  }], null, {
    range: [{
      type: Input
    }]
  });
})();
const url = (control, options) => {
  if (isPresent(Validators.required(control))) {
    return null;
  }
  const v = control.value;
  return isURL(v, options) ? null : {
    url: true
  };
};
const URL_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => UrlValidator),
  multi: true
};
class UrlValidator {
  /** validation options defaulting to
   * { protocols: ['http','https','ftp'], require_tld: true, require_protocol: false, require_host: true, require_port: false, require_valid_protocol: true, allow_underscores: false, host_whitelist: false, host_blacklist: false, allow_trailing_dot: false, allow_protocol_relative_urls: false, allow_fragments: true, allow_query_components: true, disallow_auth: false, validate_length: true }
   * */
  urlOptions;
  validate(c) {
    return url(c, this.urlOptions);
  }
  static ɵfac = function UrlValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || UrlValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: UrlValidator,
    selectors: [["", "ngv-url", "", "formControlName", ""], ["", "ngv-url", "", "formControl", ""], ["", "ngv-url", "", "ngModel", ""]],
    inputs: {
      urlOptions: "urlOptions"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([URL_VALIDATOR])]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UrlValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-url][formControlName],[ngv-url][formControl],[ngv-url][ngModel]',
      providers: [URL_VALIDATOR],
      standalone: true
    }]
  }], null, {
    /** validation options defaulting to
     * { protocols: ['http','https','ftp'], require_tld: true, require_protocol: false, require_host: true, require_port: false, require_valid_protocol: true, allow_underscores: false, host_whitelist: false, host_blacklist: false, allow_trailing_dot: false, allow_protocol_relative_urls: false, allow_fragments: true, allow_query_components: true, disallow_auth: false, validate_length: true }
     * */
    urlOptions: [{
      type: Input
    }]
  });
})();
const uuids = {
  3: /^[0-9A-F]{8}-[0-9A-F]{4}-3[0-9A-F]{3}-[0-9A-F]{4}-[0-9A-F]{12}$/i,
  4: /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  5: /^[0-9A-F]{8}-[0-9A-F]{4}-5[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i,
  all: /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i
};
const uuid = version => {
  return control => {
    if (isPresent(Validators.required(control))) {
      return null;
    }
    const v = control.value;
    const pattern = uuids[version] || uuids.all;
    return new RegExp(pattern).test(v) ? null : {
      uuid: true
    };
  };
};
const UUID_VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => UUIDValidator),
  multi: true
};
class UUIDValidator {
  uuid;
  validator;
  onChange;
  ngOnInit() {
    this.validator = uuid(this.uuid);
  }
  ngOnChanges(changes) {
    for (const key in changes) {
      if (key === 'uuid') {
        this.validator = uuid(changes[key].currentValue);
        if (this.onChange) {
          this.onChange();
        }
      }
    }
  }
  validate(c) {
    return this.validator(c);
  }
  registerOnValidatorChange(fn) {
    this.onChange = fn;
  }
  static ɵfac = function UUIDValidator_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || UUIDValidator)();
  };
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: UUIDValidator,
    selectors: [["", "ngv-uuid", "", "formControlName", ""], ["", "ngv-uuid", "", "formControl", ""], ["", "ngv-uuid", "", "ngModel", ""]],
    inputs: {
      uuid: "uuid"
    },
    standalone: true,
    features: [i0.ɵɵProvidersFeature([UUID_VALIDATOR]), i0.ɵɵNgOnChangesFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UUIDValidator, [{
    type: Directive,
    args: [{
      selector: '[ngv-uuid][formControlName],[ngv-uuid][formControl],[ngv-uuid][ngModel]',
      providers: [UUID_VALIDATOR],
      standalone: true
    }]
  }], null, {
    uuid: [{
      type: Input
    }]
  });
})();
const CustomValidators = {
  arrayLength,
  base64,
  creditCard,
  date,
  dateISO,
  digits,
  email,
  equal,
  equalTo,
  gt,
  gte,
  includedIn,
  json,
  lt,
  lte,
  max,
  maxDate,
  min,
  minDate,
  notEqual,
  notEqualTo,
  notIncludedIn,
  notMatching,
  number,
  property,
  range,
  rangeLength,
  url,
  uuid
};
const CustomDirectives = [ArrayLengthValidator, Base64Validator, CreditCardValidator, DateValidator, DateISOValidator, DigitsValidator, EmailValidator, EqualValidator, EqualToValidator, GreaterThanValidator, GreaterThanEqualValidator, IncludedInValidator, JSONValidator, LessThanValidator, LessThanEqualValidator, MaxValidator, MaxDateValidator, MinValidator, MinDateValidator, NotEqualValidator, NotEqualToValidator, NotIncludedInValidator, NotMatchingValidator, NumberValidator, PropertyValidator, RangeValidator, RangeLengthValidator, UrlValidator, UUIDValidator];
class CustomFormsModule {
  static ɵfac = function CustomFormsModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || CustomFormsModule)();
  };
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: CustomFormsModule
  });
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CustomFormsModule, [{
    type: NgModule,
    args: [{
      imports: [CustomDirectives],
      exports: [CustomDirectives]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ArrayLengthValidator, Base64Validator, CreditCardValidator, CustomFormsModule, CustomValidators, DateISOValidator, DateValidator, DigitsValidator, EmailValidator, EqualToValidator, EqualValidator, GreaterThanEqualValidator, GreaterThanValidator, IncludedInValidator, JSONValidator, LessThanEqualValidator, LessThanValidator, MaxDateValidator, MaxValidator, MinDateValidator, MinValidator, NotEqualToValidator, NotEqualValidator, NotIncludedInValidator, NotMatchingValidator, NumberValidator, PropertyValidator, RangeLengthValidator, RangeValidator, UUIDValidator, UrlValidator, arrayLength, base64, creditCard, date, dateISO, digits, email, equal, equalTo, gt, gte, includedIn, json, lt, lte, max, maxDate, min, minDate, notEqual, notEqualTo, notIncludedIn, notMatching, number, property, range, rangeLength, url, uuid };
